import React from 'react';
import CityGuide from '../../../../components/guides/CityComponent';
import Footer from '../../../../components/FooterComponent';

import { MuiNe } from '../../../../guides/countries/vietnam/mui-ne';

function MuiNePage(props) {
    return (
        <div>
            <CityGuide city={ MuiNe } pathname={ props.location.pathname } urlBack="/travel-guides/asia/vietnam" />
            <Footer />
        </div>
    );
}

export default MuiNePage;
